import "@css/app.css";

/* JS */
import "lazysizes";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();


// import Swiper JS
import Swiper from 'swiper/bundle';
// import Swiper styles
import 'swiper/css/bundle';
import 'swiper/css/effect-fade';

const swiper1 = new Swiper('.hero-swiper', {
  slidesPerView: 1,
  spaceBetween: 100,
  loop: true,
  effect: "fade",
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
});

const swiper2 = new Swiper('.cp-swiper', {
  slidesPerView: 3,
  spaceBetween: 50,
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  /*autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },*/
  breakpoints: {
    640: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 50,
    },
  },
});

class InlineVideo {
  constructor(el) {
    this._el = el;
    this._video = el.dataset.video;
    this._image = el.querySelector(".js-video__placeholder");

    this._el.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick() {
    this._el.insertAdjacentHTML(
      "beforeend",
      `<iframe src="${this._video}?autoplay=1&rel=0" frameborder="0"></iframe>`
    );
    this._image.classList.add("fade-out");

    setTimeout(() => {
      this._image.remove();
    }, 700);

    this._el.removeEventListener("click", this.handleClick);
  }
}

Array.from(document.querySelectorAll(".js-video")).forEach((video) => {
  new InlineVideo(video);
});